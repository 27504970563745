import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule,routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiServiceService } from './services/api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonfunctionService } from './services/commonFunctionService';
import { BrowserService } from './services/browser.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShareService } from './services/share.service';
// import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';
import { UserComponent } from './home/component/user/user.component';
import { RegistrationComponent } from './home/component/user/registration/registration.component';
import { LoginComponent } from './home/component/user/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationService } from './services/notification.service';
import { MatModules } from './mat-modules';
// import { CitySelectionComponent } from './core/components/city-selection/city-selection.component';
import { TermsComponent } from './core/components/pages/terms/terms.component';
import { PrivacyComponent } from './core/components/pages/privacy/privacy.component';
// import { ContactUsComponent } from './core/components/pages/contact-us/contact-us.component';

import { ConfirmDailogComponent } from './core/components/confirm-dailog/confirm-dailog.component';
import { LoadDataService } from './services/load-data.service';
import { SharedModule } from './core/shared-module/shared-module.module';
import { IpBlockComponent } from './core/components/ip-block/ip-block.component';
import { ResetPasswordComponent } from './home/component/user/reset-password/reset-password.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoaderModule } from './core/loader/loader.module';
import { CitySelectionDialogComponent } from './core/components/city-selection-dialog/city-selection-dialog.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { SocialLoginComponent } from './home/component/user/social-login/social-login.component';
import { InterceptorService } from './services/interceptor.service';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
// import { ECHomeModule } from './ec/ec-home/home/home.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './loaders/translate-browser.loader';
// import { EMHomeModule } from './home/home.module';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { localizeBrowserLoaderFactory } from './loaders/localize-browser.loader';
import { Location } from '@angular/common';
// import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { ConfirmationDialogComponent } from './core/components/confirmation-dialog/confirmation-dialog.component';
import { AlertModule } from './ec/ec-home/services/alert/alert.module';
import {RouterModule} from '@angular/router';
import { MetaGuard } from './services/meta-guard.service';
import { successComponent } from './success/success.component';
import { OtpInputComponent } from './home/component/user/reset-password/otp-input/otp-input.component';
import { WINDOW_PROVIDERS } from './window.providers';
import { ErrorInterceptor } from './error-interceptor';
// import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';

// let config = new SocialAuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.google_key)
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(environment.facebook_key)
//   }
// ]);

// export function provideConfig() {
//   return config;
// }

// export function metaFactory(translate: TranslateService): MetaLoader {
//   return new MetaStaticLoader({
//     callback: (key: string) => translate.get(key),
//     pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
//     // pageTitleSeparator: ' - ',
//     // applicationName: 'APP_NAME',
//     // defaults: {
//     //   title: 'DEFAULT_TITLE',
//     //   description: 'DEFAULT_DESC',
//     //   'og:image': 'https://upload.wikimedia.org/wikipedia/commons/f/f8/superraton.jpg',
//     //   'og:type': 'website',
//     //   'og:locale': 'en',
//     //   'og:locale:alternate': 'en,arb'
//     // }
//   });
// }


@NgModule({
    declarations: [
        AppComponent, UserComponent, RegistrationComponent,ConfirmDailogComponent, LoginComponent, TermsComponent, PrivacyComponent,
        IpBlockComponent, ResetPasswordComponent, CitySelectionDialogComponent,
        SocialLoginComponent,
        ConfirmationDialogComponent, successComponent, OtpInputComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverECEM' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FlexLayoutModule,
        FormsModule,
        AlertModule,
        BrowserTransferStateModule,
        ReactiveFormsModule,
        // NgxSmartBannerModule,
        // TourNgxBootstrapModule.forRoot(),
        ...MatModules,
        SharedModule,
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            logOnly: true, // Restrict extension to log-only mode
        }),
        LoaderModule,
        SocialLoginModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        SocialLoginModule ,
        RouterModule.forRoot(routes),
        // ECHomeModule,
        // EMHomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState],
            },
        }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: localizeBrowserLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient, TransferState],
            },
            initialNavigation: true,
            alwaysSetPrefix: false
        }),
        // MetaModule.forRoot({
        //     provide: MetaLoader,
        //     useFactory: (metaFactory),
        //     deps: [TranslateService]
        // }),
    ],
    providers: [
        ApiServiceService,
        CommonfunctionService,
        BrowserService,
        ShareService,
        NotificationService,
        LoadDataService,
        MetaGuard,
        WINDOW_PROVIDERS,
        [
            {
              provide: 'SocialAuthServiceConfig',
              useValue: {
                autoLogin: false,
                providers: [
                  {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider(environment.facebook_key)
                  }
                ],
                onError: (err) => {
                  console.error(err);
                }
              } as SocialAuthServiceConfig,
            }
          ],
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6Lc4aOIjAAAAAJG0LE_Oyd8Nn3pUEC_5ZIpwzeng'
            } as RecaptchaSettings
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: [...MatModules, OtpInputComponent]
})
export class AppModule {}
